.footer {
    margin-top: 10px;
    /* border-top: 0.5px solid #163562;
    border-radius: 20px; */
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}

.copyright{
    color: #163562;
    font-size: 1.3rem;
}

.socials{
    justify-content: center;
    margin: 0 auto;
    align-items: center;
    display: flex;
}

.socials {
    
    margin-right: 10px;
    color: #163562;
    margin-top:10px;
    gap: 5px;
 

}

.icons{
font-size: 2em;

}

@media only screen and (max-width: 600px) {
    .copyright{
        color: #163562;
        font-size: 0.8rem;
    }
    .icons{
        font-size: 1em;
    }
    .footer {
        margin-top: 1%;
    }
}
