@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Figtree&display=swap');
body {
  margin: 0;
  font-family: 'Figtree', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #E5E5E5;

  animation: smoothOpen 1.5s ease-out forwards;
}
  @keyframes smoothOpen {
    from {
      opacity: 0;
      transform: scale(0);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }



