.bus-photo{
    width: 100%;
    object-fit: contain;
    filter: brightness(90%);
    /* margin-top: 10%; */
}

.soon {
    text-align: center;
    padding: 20px;
    text-align: center;
    padding: 20px;
    font-size: 1.5rem;
    color: #808080;
    font-weight: 300;
    letter-spacing: 1px;
  }
  
  .heading {
    font-size: 3rem;
    color: #163562;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    animation: heading-glow 3s ease-in-out infinite;
    margin-top: 30px;
    text-align: center;
  }
  @keyframes heading-glow {
    0% {
      text-shadow: none;
    }
    50% {
      text-shadow: 0 0 10px #163562;
    }
    100% {
      text-shadow: none;
    }
  }
  .subtitles {
    font-size: 1.4rem; 
    color: #163562;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    margin-top: 30px;
    text-align: center;

  }

  @media only screen and (max-width: 600px) {
    .heading {
        font-size: 2.3rem;
  }
  .subtitles {
    font-size: 1rem; 
  }

}

  
  
